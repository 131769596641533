

































import { Component, Prop, Vue } from 'vue-property-decorator'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  inheritAttrs: false,
  components: {
    MultiselectV2,
    IconWarningTriangle,
  },
})
export default class ProgramMultiselectV2 extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
}
