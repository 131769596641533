






























import { Component, Prop, Vue } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  inheritAttrs: false,
  components: {
    DropdownSelect,
    IconWarningTriangle,
  },
})
export default class ProgramDropdown extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
}
