import {
  TriggerType,
  UserType,
} from '@/app/infrastructures/misc/Constants/programaticPoint'

interface ProgrammaticPointState {
  isParcelPointTrigger: (triggerBy: string | undefined) => boolean
  isParcelPointUserTarget: (userTarget: string | undefined) => boolean
  isParcelPointRelease: (pointPurpose: string | undefined) => boolean
  isParcelPointCondition: (
    pointPurpose: string | undefined,
    triggerBy: string | undefined,
    userTarget: string | undefined
  ) => boolean
}

const useProgrammaticPoint = (): ProgrammaticPointState => {
  const isParcelPointTrigger = (triggerBy: string | undefined): boolean => {
    return [
      TriggerType.REFERRAL_CODE_USED,
      TriggerType.REFERRAL_INVITEE,
      TriggerType.REGISTRATION,
      TriggerType.TRANSACTION_DONE,
      TriggerType.COMPLETE_PROFILE,
    ].includes(<TriggerType>triggerBy?.toUpperCase() || '')
  }

  const isParcelPointUserTarget = (userTarget: string | undefined): boolean => {
    return [
      UserType.LOGIN,
      UserType.WITH_REFERRAL,
      UserType.WITHOUT_REFERRAL,
      UserType.SELECTED,
      UserType.MEMBERSHIP,
    ].includes(<UserType>userTarget?.toUpperCase() || '')
  }

  const isParcelPointRelease = (pointPurpose: string | undefined): boolean => {
    if (typeof pointPurpose === 'string') {
      return pointPurpose.trim() === ''
    }
    return false
  }

  const isParcelPointCondition = (
    pointPurpose: string | undefined,
    triggerBy: string | undefined,
    userTarget: string | undefined
  ): boolean => {
    return (
      isParcelPointRelease(pointPurpose) &&
      isParcelPointTrigger(triggerBy) &&
      isParcelPointUserTarget(userTarget)
    )
  }

  return {
    isParcelPointTrigger,
    isParcelPointUserTarget,
    isParcelPointRelease,
    isParcelPointCondition,
  }
}

export default useProgrammaticPoint
