












import { Component, Prop, Vue } from 'vue-property-decorator'
import IconWarningCircle from '@/app/ui/assets/icon_warning_circle.vue'
import IconCheckCircle from '@/app/ui/assets/ics_f_check_circle.vue'

@Component({
  name: 'Note',
  components: {
    IconWarningCircle,
    IconCheckCircle,
  },
})
export default class Modal extends Vue {
  @Prop({ default: 'give me a note' }) private text!: string
  @Prop({ required: false, default: 'normal'}) private type!: 'normal' | 'success' | 'failed'
  @Prop({ required: false, default: '' }) private customClass!: string

  get isFailedOrNormal(): boolean {
    return this.type === 'normal' || this.type === 'failed'
  }
}
