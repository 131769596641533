











































import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import InputMultiTags from '@/app/ui/components/InputMultiTags/index.vue'

@Component({
  components: {
    TextInput,
    IconWarningTriangle,
    InputMultiTags,
  },
})
export default class ProgramMultiTagTextInput extends Vue {
  @Prop({ type: String, default: 'input-tag' }) id!: string
  @Prop({ required: false }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private fieldSuffix!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
  @Prop({ default: false }) private isMultiTag!: boolean
  @Prop({ default: 0 }) private tagLimit!: number

  private onInputMultiTags(val: string[]) {
    if(this.tagLimit != 0 && val.length >= this.tagLimit + 1) {
      return
    }
    this.$emit('update:modelValue', val)
  }
}
