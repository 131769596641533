




















import { Component, Prop, Vue } from 'vue-property-decorator'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import { LabelValue } from '@/app/ui/components/OptionBox/interfaces';

@Component({
  inheritAttrs: false,
  components: { RadioButton }
})
export default class ProgramDatePicker extends Vue {
  @Prop({ required: true, default: '?' }) private fieldLabel!: string
  @Prop({ required: false, default: '' }) private subFieldLabel!: string
  @Prop({ default: false }) private isRequire!: boolean
  @Prop({ required: true }) private name!: string
  @Prop({ required: true }) private options!: Array<LabelValue<string>>
  @Prop({ required: false, default: false }) private isEditMode!: boolean

  changeValue(val: string): void {
    this.$emit('input', val)
  }
}
